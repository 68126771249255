import { NetworkContext } from "../Net"
import React, { useContext, useState, useEffect } from "react";

export function ErrorAlert(props: {
    message?: string,
    onDismiss: () => void;
    }) {
    return props.message != null ? <div className="alert alert-danger">
    <strong>An error occurred</strong> <span>{props.message}</span>
    <button type="button" className="close text-white" data-dismiss="alert" aria-label="Close" onClick={() => {
        props.onDismiss();
    }}>
        <span aria-hidden="true">&times;</span>
    </button>
    </div> : <></>;
}