import { RouteComponentProps } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";
import { DecodedResponse, NetworkContext } from "../Net";

export function FinishLogin(props: {onSuccess: () => {}} & RouteComponentProps) {
    const net = useContext(NetworkContext);

    const linkFromLoc = () => {
        const n = props.location.search.indexOf('link=');
        if (n >= 0) {
            return props.location.search.substring(n + 'link='.length);
        }
        return '';
    }
    const [autosubmit, setAutosubmit] = useState(false);
    const [link, setLink] = useState(linkFromLoc);
    
    useEffect(() => {
        const link = linkFromLoc();
        if (link !== '') {
            setLink(link)
            setAutosubmit(true);
        }
    }, [props.location]);
    useEffect(() => {
        if (autosubmit) {
            doSubmit(link);
        }
    }, [autosubmit, link])
    const doSubmit = (linkString: string) => {
        net.doFetch(`/api/auth/login?link=${linkString}`, 'GET').then((userRsp: DecodedResponse<any>) => {
            if (!userRsp.isError) {
                props.history.push('/');
                props.onSuccess();
            }
        });
    }
    return <div className="text-center">
        <h4>Check your e-mail for the link to login</h4>
        <div>
        Link <input type="text" value={link} 
            onPaste={(e) => {
                e.preventDefault();
                const d = e.clipboardData.getData('text/plain');
                const n = d.indexOf('link=');
                if (n >= 0) {
                    setLink(d.substring(n + 'link='.length));
                } else {
                    setLink(d);
                }
            }}
            onChange={(e) => {
            setLink(e.currentTarget.value);
        }} /><button type="button" onClick={() => {
            doSubmit(link);
        }}>Send</button>
        </div>
    </div>;
}