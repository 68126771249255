import React, { useEffect, useContext, useState, useMemo } from 'react';
import { SessionContext, DecodedResponse, NetworkContext } from '../Net';
import { BarChart } from '../components/BarChart';
import { UncontrolledPopover, PopoverHeader, PopoverBody, Modal, ModalBody } from 'reactstrap';

interface ActivityData {
    host_activity: Array<HostActivity>
}

interface HostActivity {
    hostname: string;
    site_activity: Array<SiteStats>;
}

type DataSource = {
    'type': 'Identified';
    data: {
        id: {
            site_file?: string;
            server_names: Array<string>;
        }; 
    }
} | {
    'type': 'Unidentified';
    data: {
        log_path: string;
    }
}

interface SiteStats {
    source: DataSource;
    stats: StatsReport;
}

interface ValueCount {
    value: string;
    count: number;
}

interface StatsReport {
    start: string;
    length_ms: number;
    total_visitors: number;
    top_pages: Array<ValueCount>;
    top_referers: Array<ValueCount>;
    device_categories_perc: Array<ValueCount>;
    os_perc: Array<ValueCount>;
    visitor_days: Array<{
        start: string;
        length_ms: number;
        stats: number;
    }>
}

function sumOtherSorted(percentages: Array<ValueCount>) {
    let otherTotal = 0.0;
    let withOther = percentages.filter((e) => {
        if (e.count < 1.0) {
            otherTotal += e.count;
            return false;
        } else {
            return true;
        }
    });
    withOther.push({
        value: 'Other',
        count: otherTotal
    });
    withOther.sort((a, b) => b.count - a.count);
    return withOther;
}


function SiteActivity(props: {
    stats: SiteStats,
    isOnlySiteInFile: boolean
}) {    
    const [logFormatInfoOpen, setLogFormatInfoOpen] = useState(false);
    const os_perc = useMemo(() => {
        return sumOtherSorted(props.stats.stats.os_perc);
    }, [props.stats]);
    const device_perc = useMemo(() => {
        return sumOtherSorted(props.stats.stats.device_categories_perc);
    }, [props.stats]);
    const top_pages = useMemo(() => {
        const sorted = [...props.stats.stats.top_pages];
        sorted.sort((a, b) => {
            return b.count - a.count;
        });
        return sorted;
    }, [props.stats]);
    const top_referers = useMemo(() => {
        const sorted = [...props.stats.stats.top_referers];
        sorted.sort((a, b) => {
            return b.count - a.count;
        });
        return sorted;
    }, [props.stats]);
    return <div className="d-block w-100 text-left" style={{maxWidth: '100%'}}>
        {props.stats.source.type === 'Identified' ? 
            <h3 className="text-truncate">{(props.isOnlySiteInFile && props.stats.source.data.id.site_file) || props.stats.source.data.id.server_names.join(' ')}</h3> :
            <div className="d-block flex-column align-items-start">
                <h3 style={{maxWidth: '100%'}} className="mb-0 text-truncate">{'Mixed sites in log ' + props.stats.source.data.log_path}</h3>
                <small className="mt-0 mb-2">Use separate log files for each site or <span className="text-primary" onClick={() => { setLogFormatInfoOpen(!logFormatInfoOpen); }}>add a host column to the log format</span> of the file to identify sites</small>
                <Modal isOpen={logFormatInfoOpen} toggle={() => { setLogFormatInfoOpen(!logFormatInfoOpen); }} size='lg'>
                    <ModalBody><div className="d-flex flex-column">
                    <div>
                    Add a log_format directive with a host field to your nginx.conf. For example:
                    </div>
                    <div className="flex-grow-1 mb-3" style={{whiteSpace: 'pre-wrap'}}>
                    log_format withhost '$remote_addr - $remote_user [$time_local] '<br></br>
                    '"$request" $status $body_bytes_sent '<br></br>
                    '"$http_referer" "$http_user_agent" '<br></br>
                    '"$host"';<br></br>
                    </div>
                    <div>Use the format name for your access_log directive. </div>
                    <div>
                    access_log /var/log/nginx/access.log withhost;
                    </div>
                    </div>
                    </ModalBody>
                </Modal>
            </div>}
        {props.stats.stats.total_visitors !== 0 ? 
        <div className="d-flex flex-column align-items-start w-100 mb-3">
            <div className="d-flex flex-row justify-content-between align-self-stretch align-items-end">
                <h5>Visitors</h5>
                <h6>Total {props.stats.stats.total_visitors}</h6>
            </div>
            <BarChart style={{minHeight: '200px'}} values={props.stats.stats.visitor_days} 
                yValueFn={(d) => d.stats} xLabelFn={(v, idx) => {
                const dateTimeFormat = new Intl.DateTimeFormat('en', { month: 'short', day: '2-digit' });
                const parts = dateTimeFormat.formatToParts(Date.parse(v.start));
                return parts.find(p => p.type === 'month')?.value + ' ' + parts.find(p => p.type === 'day')?.value;
            }}/>
            <div className="w-100 row">
                <div className="col-md-3 d-flex flex-column align-items-start">
                    <h5>Top Pages</h5>
                    <table className="table table-sm">
                        <tbody>
                        {top_pages.map(({value, count}, idx) => {
                            return <tr key={'tp-' + idx}><td className="text-break w-100">{value}</td><td>{Math.round(count)}</td></tr>;
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-3 d-flex flex-column align-items-start">
                    <h5>Top Referrers</h5>
                    <table className="table table-sm">
                        <tbody>
                        {top_referers.map(({value, count}, idx) => {
                            return <tr key={'tr-' + idx}><td className="text-break w-100">{value}</td><td>{Math.round(count)}</td></tr>;
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-3 d-flex flex-column align-items-start">
                    <h5>Devices</h5>
                    <table className="table table-sm">
                        <tbody>
                        {device_perc.map(({value, count}, idx) => {
                            return <tr key={'dp-' + idx}><td className="text-break w-100">{value}</td><td>{Math.round(count) + '%'}</td></tr>;
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-3 d-flex flex-column align-items-start">
                    <h5>Operating Systems</h5>
                    <table className="table table-sm">
                        <tbody>
                        {os_perc.map(({value, count}, idx) => {
                            return <tr key={'os-' + idx}><td className="text-break w-100">{value}</td><td>{Math.round(count) + '%'}</td></tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div> :
        <div className="mb-3">No data for the period could be attributed to the site.</div>}
    </div>;
}

export function Activity(props: {}) {
    const sessionContext = useContext(SessionContext);
    const net = useContext(NetworkContext);
    const [activityData, setActivityData] = useState<ActivityData>();
    useEffect(() => {
        if (sessionContext.isLoggedIn) {
            net.doFetch('/api/activity', 'GET').then((rsp: DecodedResponse<ActivityData>) => {
                if (!rsp.isError) {
                    const ad: ActivityData = rsp.data;
                    ad.host_activity.sort((a, b) => a.hostname.localeCompare(b.hostname));
                    const compareSiteNames = (a: DataSource, b: DataSource) => {
                        if (a.type === 'Identified') {
                            if (b.type === 'Identified') {
                                return a.data.id.server_names[0].localeCompare(b.data.id.server_names[0]);
                            } else {
                                return -1;
                            }
                        } else {
                            if (b.type === 'Identified') {
                                return 1;
                            } else {
                                return a.data.log_path.localeCompare(b.data.log_path);;
                            }
                        }
                    };
                    ad.host_activity.forEach((ha) => {
                        ha.site_activity.sort((a, b) => {
                            if (a.stats.total_visitors === b.stats.total_visitors ||
                                (a.stats.total_visitors !== 0 &&
                                b.stats.total_visitors !== 0)) {
                                return compareSiteNames(a.source, b.source);
                            } else {
                                if (a.stats.total_visitors === 0) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            } 
                        });
                    });
                    setActivityData(ad);
                }
            })
        }
    }, []);
    return <div className="d-flex flex-column align-items-start">
        <h1>Activity</h1>
        {activityData != null && activityData.host_activity.length > 0 ? 
            activityData.host_activity[0].site_activity
            .filter((sa) => {
                return sa.source.type == 'Identified' || 
                    sa.stats.total_visitors > 0 || 
                    activityData.host_activity[0].site_activity.length == 1;
            })
            .map( (sa, idx) => <SiteActivity 
            isOnlySiteInFile={activityData.host_activity[0].site_activity.find((s, idx2) => idx != idx2 &&
                sa.source.type == 'Identified' && s.source.type == 'Identified' && 
                s.source.data.id.site_file === sa.source.data.id.site_file) == null}
            key={'site-' + idx} 
            stats={sa} />) 
        : <div>No activity received. Start a bot on a host to receive activity.</div>}
    </div>
}