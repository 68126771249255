import React, { useContext } from 'react';
import { LoggedInSession, NetworkContext } from "../Net";
import { RouteComponentProps } from 'react-router-dom';

export function Profile(props: {session: LoggedInSession} & RouteComponentProps) {
    const net = useContext(NetworkContext);
    return <div className="d-flex flex-column align-items-start">
        <h1>User Account</h1>
        <div>Logged in as {props.session.user.name}</div>
        <button type="button" className="btn btn-danger" onClick={async () => {
            const rsp = await net.doFetch('/api/auth', 'DELETE');
            if (!rsp.isError) {
                net.initializeSession();
                props.history.push('/');
            }
        }}>Logout</button>
    </div>;
}