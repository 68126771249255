import React, { useState, useContext } from 'react'; 
import { RouteComponentProps } from 'react-router-dom';
import { SessionContext, NetworkContext } from './Net';

function LoginOrRegister(props: {
    isRegister: boolean
} & RouteComponentProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const sessionContext = useContext(SessionContext);
  const net = useContext(NetworkContext);
  return  <form>
  <legend>{props.isRegister ? 'Register' : 'Login'}</legend>
    {props.isRegister ?
    <div className="form-group row no-gutters">
      <label className="col-2 col-form-label">Name</label>
      <input className="col-10 form-control" type="text" value={name} onChange={(e) => {setName(e.currentTarget.value)}}></input>
    </div> : 
    <></>}
    <div className="form-group row no-gutters">
      <label className="col-2 col-form-label">E-mail</label>
      <input className="col-10 form-control" type="email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}}></input>
    </div>
    <button className="btn btn-outline-primary" type="button" onClick={() => {
      net.doFetch('/api/auth/' + (props.isRegister ? 'register' : 'login'), 'POST', props.isRegister ? {
          name,
          email
      } : 
      {email}).then((rsp) => {
        if (!rsp.isError) {
          props.history.push('/finishLogin')
        }
      });
    }}>Submit</button>
  </form>;
}

export default LoginOrRegister;