import React from "react";

const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':8080' : '')

export interface LoggedInSession {
    isLoggedIn: true;
    user: {
      name: string;
      email: string;
      termsApproved?: any;
    }
}

export type Session = {isLoggedIn: false
    } | LoggedInSession;

export type Network = {
    initializeSession: () => void;
    doFetch: (path: string, method: string, body?: object, suppressError?: boolean) => Promise<DecodedResponse<any>>;
    errorHandler: (response: DecodedResponse<any>) => void;
};

export const SessionContext = React.createContext<Session>({isLoggedIn: false});

export const NetworkContext = React.createContext<Network>({
    initializeSession: () => {},
    doFetch: async function (path: string, method: string, body?: object, suppressError?: boolean) {
        const h: Record<string, string> = {};
        const token = getCsrfToken();
        if (method !== 'GET') {
          h['X-XSRF-TOKEN'] =  token || '';
          h['Content-Type'] = 'application/json'
        }
        const resp = await window.fetch(baseUrl + path, {
            credentials: 'include',
            headers: h,
            cache: 'no-store',
            method: method,
            body: JSON.stringify(body)
          }).then((response: Response) => {
            if (response.ok) {
              return response.json().then(json => {
                  return {
                    isError: false,
                    data: json.data
                  } as DecodedResponse<any>;
                }).catch((err) => {
                  return {
                    isError: true,
                    message: `Failed to decode response: ${err}`
                  } as DecodedResponse<any>;
                });
            } else {
              return {
                  isError: true,
                  code: response.status,
                  message: response.statusText
                } as DecodedResponse<any>;
            }
          }).catch(err => {
            return {
              isError: true,
              message: `Failed to fetch ${err}`
            } as DecodedResponse<any>;
          });
        if (resp.isError && suppressError !== true) {
          this.errorHandler(resp);
        }
        return resp;
      },
      errorHandler: (response: DecodedResponse<any>) => {
        if (response.isError) {
          console.log(`Error ${response.code || ''}: ${response.message}`)
        }
      }
});

export function getCsrfToken(): string | undefined {
  const csrfCookie = 'XSRF-TOKEN'
  const cookies = decodeURIComponent(document.cookie).split(';')
  for(var i = 0; i <cookies.length; i++) {
        var c = cookies[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(csrfCookie) === 0) {
            return c.substring(csrfCookie.length + 1, c.length);
        }
    }
    return undefined;
}

export type DecodedResponse<T> = {
  isError: false;
  data: T;
} | {
  isError: true;
  code?: number;
  message: string;
};