import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SessionContext, DecodedResponse, NetworkContext } from '../Net';

interface AccessLog {
    path: string;
    format_name?: string;
}

interface LogFormat {
    name: string;
    format: string;
}

interface Gateway {
    hostname: string,
    nginx?: {
        config_dir: string;
        global: {
            access_log?: Array<AccessLog>;
            log_format?: Array<LogFormat>;
        },
        sites_enabled: Array<{
            id: {
                site_file?: string,
                server_names: Array<string>
            },
            access_log?: Array<AccessLog>;
        }>
    }
}

function Gateway(props: {} & RouteComponentProps) {
    const sessionContext = useContext(SessionContext);
    const net = useContext(NetworkContext);
    const [gateways, setGateways] = useState<Array<Gateway>>([]);
    useEffect(() => {
        if (sessionContext.isLoggedIn) {
            net.doFetch('/api/gateway', 'GET').then((rsp: DecodedResponse<Array<Gateway>>) => {
                if (!rsp.isError) {
                    setGateways(rsp.data)
                }
            })
        }
    }, []);
    return <div className="d-flex flex-column align-items-stretch">
        <h1 className="align-self-start mb-3">Gateways</h1>
        {gateways.length === 0 ? 
            <div>No gateways registered. Start a bot on a gateway host!</div>:
            gateways.map((g, idx) => {
            return <div key={'g-'+idx}>
            <span className="d-block p-2 bg-primary text-white text-left"><strong>Host:</strong> {g.hostname}</span>
            <div className="d-flex flex-column align-items-stretch px-1">
              <h2 className="align-self-start">NGINX</h2>
              {g.nginx == null ? <div>No NGINX server found on gateway</div> :
              <>
              <div className="form-group d-flex flex-row justify-content-start align-items-center">
                <label className="form-label" style={{minWidth: '100px'}}>Config Dir</label>
                <input className="form-control bg-white" type="text" value={g.nginx.config_dir} readOnly={true}/>
              </div>
              {g.nginx.global.access_log?.map((al, idx) => {
                return <div key={'gal-'+idx} className="form-group d-flex flex-row justify-content-start align-items-center">
                    <label className="form-label" style={{minWidth: '100px'}}>Access Log</label>
                    <input className="form-control bg-white" type="text" value={al.path} readOnly={true}/>
                </div>;
              }) ?? <></>}
              {g.nginx.global.log_format?.map((lf, idx) => {
                return <div key={'glf-'+idx} className="form-group d-flex flex-row justify-content-start align-items-center">
                    <label className="form-label" style={{minWidth: '100px'}}>Log Format</label>
                    <input className="form-control bg-white" type="text" value={lf.name} readOnly={true}/>
                </div>;
                }) ?? <></>}
              <h5 className="align-self-start">Enabled Sites</h5>
              {g.nginx.sites_enabled.length === 0 ? 
                <div>No enabled sites</div> :
                g.nginx.sites_enabled.map((se, siteidx) => {
                    return <div className="d-flex flex-column" key={'s-'+siteidx}>
                        <strong className="align-self-start">{se.id.site_file || 'Server in main nginx.conf'}</strong>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center">
                            <label className="form-label" style={{minWidth: '120px'}}>Server Names</label>
                            <input className="form-control bg-white" type="text" value={se.id.server_names.join(" ")} readOnly={true}/>
                        </div>
                        {se.access_log?.map((al, idx) => {
                            return <div key={'sal-' + siteidx +'-'+idx} className="form-group d-flex flex-row justify-content-start align-items-center">
                                <label className="form-label" style={{minWidth: '100px'}}>Access Log</label>
                                <input className="form-control" type="text" value={al.path} readOnly={true} />
                            </div>;
                          }) ?? <></>}
                          {
                            //   se.log_format?.map((lf, idx) => {
                            // return <div key={'slf-' + siteidx +'-'+idx} className="form-group d-flex flex-row justify-content-start align-items-center">
                            //     <label className="form-label" style={{minWidth: '100px'}}>Log Format</label>
                            //     <input className="form-control" type="text" value={lf.name} readOnly={true}/>
                            // </div>;
                            // }) ?? <></>
                        }
                    </div>;
                })}
              </>}
            </div>
          </div>; 
        })}
    </div>;
}

export default Gateway;