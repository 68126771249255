import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NetworkContext } from '../Net';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ErrorAlert } from './ErrorAlert';

function TermsModalInner(props: {
    isOpen: boolean,
    unblockedPath: string   // Dialog should not show for this path, and should direct to this if rejected
    } & RouteComponentProps) {
    let net = useContext(NetworkContext);
    let [errorMsg, setErrorMsg] = useState<string>();
    let [termsApproved, setTermsApproved] = useState(false);
    return props.history.location.pathname !== props.unblockedPath ? <Modal size="xl" isOpen={props.isOpen} backdrop={'static'}>
        <ModalHeader>Confirm terms of service</ModalHeader>
        <ModalBody className="d-flex flex-column" style={{height: '70vh'}}>
        {errorMsg ? <ErrorAlert message={errorMsg} onDismiss={() => { setErrorMsg(undefined) }} /> : <></>}
        <iframe className="flex-grow-1 w-100 border-0" src={'terms.html'} />
        <div className="form-check form-group mt-3 mb-0">
            <label className="form-check-label">
                <input className="form-check-input" type="checkbox" checked={termsApproved} onChange={(e) => {
                    setTermsApproved(e.currentTarget.checked);
                }}></input>I have read and approve the terms of service and privacy policy 
            </label>
        </div>
        </ModalBody>
        <ModalFooter>
            <button className="btn btn-primary" type="button" onClick={() => {
                props.history.push(props.unblockedPath);
            }}>Reject</button>
            <button className="btn btn-primary" type="button" disabled={!termsApproved} onClick={async () => {
                let rsp = await net.doFetch('/api/user', 'PUT', {termsApproved: true}, true);
                if (!rsp.isError) {
                    net.initializeSession();
                } else {
                    setErrorMsg(rsp.code + ':' + rsp.message);
                }
            }}>Approve</button>
        </ModalFooter>
    </Modal> : <></>;
}

export const TermsModal = withRouter(TermsModalInner);