import React, { useState, useEffect, useContext } from "react";
import { NetworkContext } from "../Net";

export function Bots(props: {}) {
    const net = useContext(NetworkContext);
    const [botKeys, setBotKeys] = useState<Array<{key: string, name: string}>>([]);
    const [updateKeys, setUpdateKeys] = useState(false);
    useEffect(() => {
      if (updateKeys) {
        setUpdateKeys(false);
        net.doFetch('/api/bot', 'GET').then((response) => {
          if (!response.isError) {
            setBotKeys(response.data);
          }
        });
      }
    }, [updateKeys]);
    useEffect(() => {
      setUpdateKeys(true);
    }, []);
    return <div className="d-flex flex-column w-100 align-items-stretch text-left">
      <h1>Bots</h1>
      <div className="d-flex flex-row justify-content-between align-items-center mb-2">
        <h4 className="text-left">Bot Keys</h4>
        <button className="btn btn-outline-primary" type="button" onClick={() => {
          net.doFetch('/api/bot', 'POST', {
            name: 'My Bot Key ' + botKeys.length,
          }).then((response) => {
            if (!response.isError) {
              setUpdateKeys(true);
            }
          });
        }} disabled={botKeys.length > 0}>New</button>
      </div>
      {botKeys.map((key, idx) => {
        return <div key={'bot-key-' + idx}>
          <div className="form-group row no-gutters">
            <label className="col-2 col-form-label">Name</label>
            <input className="col-10 form-control" type="text" defaultValue={key.name} contentEditable={false}></input>
          </div>
          <div className="form-group row no-gutters align-items-center">
            <label className="col-2 col-form-label">Key</label>
            <div className="d-flex flex-row align-items-center col-10">
                <input className=" form-control" id={'keyfield-' + idx} type="text" value={key.key} readOnly={true}></input>
                <button className="btn btn-outline-primary ml-2 p-2" type="button" 
                onClick={() => {
                    let elem = document.getElementById('keyfield-' + idx) as HTMLInputElement;
                    elem.select();
                    elem.setSelectionRange(0, 9999);
                    document.execCommand('copy');
                }}
                ><i className="fas fa-lg fa-copy"></i></button>
            </div>
          </div>
        </div>
      })}
    </div>;
}