import React, { useState, useEffect, useContext } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import './App.css';
import LoginOrRegister from './LoginOrRegister';
import { SessionContext, DecodedResponse, Session, NetworkContext } from './Net';
import Main from './pages/Main';
import { NavbarBrand, Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import Gateway from './pages/Gateway';
import { FinishLogin } from './pages/FinishLogin';
import { Bots } from './pages/Bots';
import { Activity } from './pages/Activity';
import { ErrorAlert } from './components/ErrorAlert';
import { TermsModal } from './components/TermsModal';
import { Profile } from './pages/Profile';


function App() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [menuOpen, setMenuOpen] = useState(false);
  const [session, setSession] = useState<Session>({
    isLoggedIn: false
  });
  const net = useContext(NetworkContext);
  useEffect(() => {
    net.errorHandler = (rsp) => {
        if (rsp.isError) {
            setErrorMessage((rsp.code ? rsp.code + ':' : '') + rsp.message);
        }
    };
    net.initializeSession = () => {
      // Get CSRF token and check if logged in
      net.doFetch('/api/user', 'GET', undefined, true).then((userRsp: DecodedResponse<{name: string, email: string}>) => {
        if (!userRsp.isError) {
          setSession({
            isLoggedIn: true,
            user: userRsp.data
          });
        } else if (userRsp.code === 403) {
          setSession({
            isLoggedIn: false
          });
        }
      });
    };
    net.initializeSession();
  }, []);
  return (<Router> 
      <SessionContext.Provider value={session}>
      <div className="App container d-flex flex-column align-items-stretch">
        <TermsModal isOpen={session.isLoggedIn && !session.user.termsApproved} unblockedPath={'/profile'} />
        <Navbar color="light" light expand="lg" className="border-0">
          <NavbarBrand>
              <Link to="/"><img src="dubmon_w344_h100.png" width="172" height="50" /></Link>
          </NavbarBrand>
          <NavbarToggler onClick={() => { setMenuOpen(!menuOpen); }} />
          <Collapse isOpen={menuOpen} navbar>
          <Nav className="mr-auto" navbar>
            {session.isLoggedIn ? <>
            <NavItem>
              <Link className="nav-link" to="/activity">Activity</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/gateway">Gateway</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/bots">Bots</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/profile">{session.user.name}</Link>
            </NavItem>
            </> : <></>}
          </Nav>
        </Collapse>
        </Navbar>
        <ErrorAlert message={errorMessage} onDismiss={() => { setErrorMessage(undefined)}}/>
        <Switch>
          <Route path="/register" component={(props: any) => {
            return <LoginOrRegister isRegister={true} {...props}/>
          }}/>
          <Route path="/login" component={(props: any) => {
            return <LoginOrRegister isRegister={false} {...props}/>
          }}/>
          <Route path="/finishLogin" component={(props: any) => {
            return <FinishLogin {...props} onSuccess={() => {
              net.initializeSession();
            }}/>
          }}/>
          <Route path="/gateway" component={(props: any) => {
            return <Gateway {...props}/>
          }}/>
          <Route path="/activity" component={(props: any) => {
            return <Activity {...props}/>
          }}/>
          <Route path="/bots" component={(props: any) => {
            return <Bots/>
          }}/>
          <Route path="/profile" component={(props: any) => {
            return session.isLoggedIn ? <Profile session={session} {...props}/> : <div>Not logged in</div>;
          }}>
          </Route>
          <Route path="/" component={(props: any) => {
            return session.isLoggedIn ? <Main></Main> : <div className="text-center">
              <Link to="/login">Login</Link> or <Link to="/register">Register</Link>
            </div>
          }}/>
        </Switch>
        <div className="mt-4 text-center">
          <small>Copyright 2020 Updab AB - <a target="_blank" href="terms.html">Terms of service</a> - <a target="_blank" href="privacy.html">Privacy policy</a> - Contact: info@dubmon.com</small>
        </div>
      </div>
      </SessionContext.Provider>
    </Router>);
}

export default App;
